import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class BaseDestroyable implements OnDestroy {
	private componentAlive = new Subject<void>();
	protected componentAlive$ = this.componentAlive.asObservable();

	ngOnDestroy(): void {
		this.componentAlive.next();
		this.componentAlive.complete();
	}
}