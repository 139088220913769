import { Injectable, ErrorHandler } from '@angular/core';
import { TimberService } from './logging/timber.service';
import { ILogParameters } from './logging/log.parameters';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
	constructor(private logger: TimberService) {}

	public handleError(error: any) {
		const timberMsg = `${error.message ? error.message : error}`;
		const logParameters: ILogParameters = { module: 'ErrorHandler' };

    this.logger.error(timberMsg,  logParameters);
		}
	}
