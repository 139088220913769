import { OnDestroy } from '@angular/core';
import { Timber } from '@itero/timber';
import { AppenderType, EventType, LogLevel } from '@itero/timber/enums';
import { BaseDestroyable } from 'src/app/core/base-destroyable';
import { IRemoteConfig } from '@itero/timber/interfaces/config.remote';
import { AppConfigService } from '../app-config.service';

export abstract class BaseTimber extends BaseDestroyable implements OnDestroy {

	protected loggerTimber: Timber;

	constructor(
    protected appConfigService: AppConfigService) {
		super();
    const config: IRemoteConfig = {
			appId: 'itero-analytics',
			appenderTypes: [ AppenderType.Remote, AppenderType.Console],
			requiredFields: ['appId', 'module'],
			eventType: EventType.Log,
			url: this.appConfigService.appSettings.loggingEndpoint,
			minLogLevel: LogLevel.All
		};
		this.loggerTimber = new Timber(config);
		console.log('Logger created with config:', config);
	}
}
