import { ILogParameters } from './log.parameters';
import { Injectable, OnDestroy } from '@angular/core';
import { BaseTimber } from './base-timber';
import { AppConfigService } from '../app-config.service';

@Injectable({
	providedIn: 'root',
})
export class TimberService extends BaseTimber implements OnDestroy{

	constructor(protected override appConfigService: AppConfigService) {
    super(appConfigService);
	}

	///write to log
	trace(message: string, parameters: ILogParameters): void { this.loggerTimber.trace(message, parameters); }

	debug(message: string, parameters: ILogParameters): void { this.loggerTimber.debug(message, parameters); }

	info(message: string, parameters?: ILogParameters): void { this.loggerTimber.info(message, parameters); }

	warn(message: string, parameters: ILogParameters): void { this.loggerTimber.warn(message, parameters); }

	error(message: string, parameters: ILogParameters): void { this.loggerTimber.error(message, parameters); }

	fatal(message: string, parameters: ILogParameters): void { this.loggerTimber.fatal(message, parameters); }
}
